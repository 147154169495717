const CLASSES = {
    COMPONENT: '.js-founder-spotlight-list',
    LISTS: '.founder-group',
}

const TIMEOUT = 10000


export default class FoundersSpotlightCarousel {
    /** @type {NodeListOf<Element> | null} */
    lists = null;
    currentListIndex = 0;
    timeoutID = null;

    constructor(el) {
        this.getElements(el)
        this.generateController()
        this.setListGroup(0)
    }

    getElements(el) {
        this.el = el
        this.lists = el.querySelectorAll(CLASSES.LISTS)
    }

    setListGroup(index) {
        if (index > this.lists.length - 1 || index < 0) return
        this.currentListIndex = index

        this.lists.forEach((list, i) =>
            list.classList.toggle('active', i === index)
        )

        this.updateController(index)
        this.wait()
    }

    wait() {
        window.clearTimeout(this.timeoutID)
        this.timeoutID = window.setTimeout(() => {
            this.nextList()
        }, TIMEOUT)
    }

    nextList() {
        if (this.currentListIndex === this.lists.length - 1) {
            return this.setListGroup(0)
        }

        return this.setListGroup(this.currentListIndex + 1)
    }

    generateController() {
        if (this.controller || this.controllerDots) return
        this.controllerDots = []
        this.controller = this.el.querySelector('.carousel-controller')

        if (!this.controller) return
        if (this.lists.length < 2) return this.controller.remove()

        const dotTemplate = document.createElement('div')
        dotTemplate.classList.add('dot')

        for (let i = 0; i < this.lists.length; i++) {
            const dot = dotTemplate.cloneNode(true)
            if (i === 0) dot.classList.add('active')
            dot.addEventListener('click', () => {
                this.setListGroup(i)
            })
            this.controller.appendChild(dot)
            this.controllerDots.push(dot)
        }
    }

    updateController(activeIndex) {
        if (!this.controllerDots || !this.controller) return
        this.controllerDots.forEach((dot, i) => {
            if (i === activeIndex) {
                dot.classList.add('active')
                return
            }

            dot.classList.remove('active')
        })
    }
}

export const FoundersSpotlightCarouselComponent = {
    name: 'FoundersSpotlightCarousel ',
    componentClass: CLASSES.COMPONENT,
    Source: FoundersSpotlightCarousel,
}
