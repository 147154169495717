const CLASSES = {
    COMPONENT: '[data-js-background-fade]',
    BODY_OVERLAY: '.body-background-overlay',
}

export default class BackgroundFade {

    constructor() {
        this.observer = this.registerObserver()
        this.layers = Array.from(document.querySelectorAll(CLASSES.COMPONENT)).map((element, idx) => {
            element.dataset.backgroundFadeLayer = idx.toString()
            this.observer.observe(element)
            return { element: element, alpha: 0 }
        })
        this.coverElements = [document.body.querySelector('.left-cover'), document.body.querySelector('.right-cover')]
        this.leftGradient = document.body.querySelector('.left-gradient')
        this.rightGradient = document.body.querySelector('.right-gradient')
        this.gradientElements = [this.leftGradient, this.rightGradient]
        document.body.classList.add('background-fade')
    }
    /**
     * @desc Creates an observer in which we can register our elements against
     */
    registerObserver() {
        // Can adjust the step if we need more or less precision
        const step = 0.2
        const options = {
            root: null,
            threshold: Array(1.0 / step).fill(0).map((_, i) => i * step),
            rootMargin: '0px',
        }
        // Grab the calculated background color of the body - some pages default to white, some to warm grey.
        const defaultBackground = this.colorToRGB(window.getComputedStyle(document.body).backgroundColor)

        // Setup an observer to watch for the elements with a callback event
        return new IntersectionObserver((entries) => {
            // Adjust the threshold for sensitivity to region changes. 0.2 is a good balance for most cases.
            const intersectionRatioThreshold = 0.2
            // For each entry, adjust the alpha of the layer based on the intersection ratio.
            // If the layer is not intersecting, set the alpha to 0.
            // We use CSS to handle the transition, so we don't need to worry about that here.
            entries.forEach((entry) => {
                if (entry.target.dataset.backgroundFadeLayer) {
                    this.layers[entry.target.dataset.backgroundFadeLayer].alpha = (entry.isIntersecting && entry.intersectionRatio > intersectionRatioThreshold) ? 1 : 0
                }
            })

            const alphaTotal = this.layers.reduce((acc, l) => acc + l.alpha, 0)
            if (alphaTotal === 0) {
                document.body.style.removeProperty('background-color')
                this.coverElements.forEach(cover => {
                    if (cover && cover.style) {
                        cover.style.removeProperty('background-color')
                    }
                })
                this.gradientElements.forEach(gradient => {
                    if (gradient && gradient.style) {
                        gradient.style.removeProperty('background')
                    }
                })
                return
            }

            // Need to "spread" the default background color to avoid modifying the original object
            let c = {...defaultBackground}

            // For each layer, calculate the color based on the alpha and the layer's color
            // An alpha of 1.0 use the layer's color, and an alpha of 0 will preserve the calculated color.
            // An alpha of 0.5 will be a 50/50 blend of the layer's color and the calculated color.
            this.layers.forEach(l => {
                let layerColor = this.colorToRGB(l.element.dataset.jsBackgroundFade)
                c.r = ((1 - l.alpha) * c.r) + (layerColor.r * l.alpha)
                c.g = ((1 - l.alpha) * c.g) + (layerColor.g * l.alpha)
                c.b = ((1 - l.alpha) * c.b) + (layerColor.b * l.alpha)
            })

            // Convert it to CSS RGB and set it on the body.
            let css = 'rgb(' + Math.round(c.r) + ', ' + Math.round(c.g) + ', ' + Math.round(c.b) + ')'
            let gradientCss = css.slice(0, -1) + ', 0)'
            document.body.style.setProperty('background-color', css)
            this.coverElements.forEach(cover => {
                if (cover && cover.style) {
                    cover.style.backgroundColor = css
                }
            })
            if (this.leftGradient && this.leftGradient.style) {
                this.leftGradient.style.background = `linear-gradient(to right, ${css}, ${gradientCss})`
            }
            if (this.rightGradient && this.rightGradient.style) {
                this.rightGradient.style.background = `linear-gradient(to left, ${css}, ${gradientCss})`
            }

            // Perceived brightness (luminance) based on https://www.w3.org/TR/AERT/#color-contrast
            // TLDR Human eyes are more sensitive to green light.
            const luminance = (0.299 * c.r + 0.587 * c.g + 0.114 * c.b) / 255
            if (luminance > 0.5) {
                document.body.classList.add('background-light')
                document.body.classList.remove('background-dark')
            } else {
                document.body.classList.add('background-dark')
                document.body.classList.remove('background-light')
            }
        }, options)
    }

    colorToRGB(c) {
        if (c[0] === '#') {
            return {
                r: parseInt(c.slice(1, 3), 16),
                g: parseInt(c.slice(3, 5), 16),
                b: parseInt(c.slice(5, 7), 16),
            }
        }
        else if (c.slice(0, 3) === 'rgb') {
            let parts = c.match(/\d+/g)
            return {
                r: parseInt(parts[0]),
                g: parseInt(parts[1]),
                b: parseInt(parts[2]),
            }
        }
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        this.observer.disconnect()
        document.body.style.removeProperty('background-color')
        this.coverElements.forEach(cover => {
            if (cover && cover.style) {
                cover.style.removeProperty('background-color')
            }
        })
        this.gradientElements.forEach(gradient => {
            if (gradient && gradient.style) {
                gradient.style.removeProperty('background')
            }
        })
    }
}

export const BackgroundFadeComponent = {
    name: 'BackgroundFade',
    componentClass: 'body', //CLASSES.COMPONENT,
    Source: BackgroundFade,
}
